<template>
  <tbody>
    <tr class="text-center">
      <td>{{ $t("labels.admin_order_report") }}</td>
      <td>
        <SelectMultipleWarehouse
          :label="$t('labels.warehouse')"
          :placeholder="$t('labels.warehouse')"
          name="id_warehouses"
          @onFilter="onFilterChange"
        />
      </td>
      <td>
        <SelectMultipleCustomerByWarehouse
          :label="$t('labels.customer')"
          :placeholder="$t('labels.customer')"
          :id-warehouses="filters.id_warehouses"
          name="id_customers"
          @onFilter="onFilterChange"
        />
      </td>
      <td>
        <DateRangeFilter
          :disabled="isDisabledDateRange"
          :min-date="minDate"
          :label="$t('labels.from_to')"
          :placeholder="$t('labels.from_to')"
          name="dates"
          @onFilter="onFilterChange"
        />
      </td>
      <td>
        <v-btn color="success" :disabled="isDisabledAction" @click="exportExcel"
          ><v-icon>mdi-download</v-icon></v-btn
        >
      </td>
      <td></td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: "Order",
  components: {
    SelectMultipleWarehouse: () =>
      import("@/components/administration/SelectMultipleWarehouse"),
    SelectMultipleCustomerByWarehouse: () =>
      import("@/components/administration/SelectMultipleCustomerByWarehouse"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
  },
  data: () => ({
    filters: {
      id_warehouses: [],
      id_customers: [],
      dates: [],
    },
    isLoading: false,
    minDate: null,
  }),
  computed: {
    isDisabledDateRange() {
      return (
        !this.filters.id_customers || this.filters.id_customers.length === 0
      );
    },
    isDisabledAction() {
      return (
        this.isDisabledDateRange ||
        !this.filters.dates ||
        this.filters.dates.length === 0
      );
    },
  },
  created() {
    const { minDate } = this.defaultDataDate({
      startDate: null,
      endDate: null,
      warehouses: (window.me && window.me.warehouses) || [],
    });
    this.minDate = minDate;
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    async exportExcel() {
      this.$emit("exportExcel", { params: this.filters, type: "order" });
    },
  },
};
</script>

<style scoped></style>
